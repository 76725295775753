import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { ParallaxProvider } from "react-scroll-parallax";
import Sophrologie from "./pages/sophrologie";
import Navbar from "./assets/components/navbar";
import Accompagnement from "./pages/accompagnement";
import Tarifs from "./pages/tarifs";
import MentionsLegales from "./pages/mentionsLegales";
import PolitiqueConf from "./pages/politiqueConf";

let router = createBrowserRouter([
  {
    path: "/",
    loader: () => HomePage,
    Component() {
      return <HomePage />;
    },
  },
  {
    path: "/sophrologie",
    loader: () => HomePage,
    Component() {
      return <Sophrologie />;
    },
  },
  {
    path: "/accompagnement-parental",
    loader: () => HomePage,
    Component() {
      return <Accompagnement />;
    },
  },
  {
    path: "/tarifs",
    loader: () => HomePage,
    Component() {
      return <Tarifs />;
    },
  },
  {
    path: "/mentions-legales",
    loader: () => MentionsLegales,
    Component() {
      return <MentionsLegales />;
    },
  },
  {
    path: "/politique-confidentialite",
    loader: () => PolitiqueConf,
    Component() {
      return <PolitiqueConf />;
    },
  },
]);

export default function App() {
  return (
    <ParallaxProvider>
      <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
      {/* <Navbar></Navbar> */}
    </ParallaxProvider>
  );
}

if ((import.meta as any).hot) {
  (import.meta as any).hot.dispose(() => router.dispose());
}
