import React, { useEffect, useLayoutEffect, useState } from "react";

import { useInView } from "@react-spring/web";
import Navbar from "../assets/components/navbar";
import Plain from "../assets/components/plain";
import Bottom from "../assets/components/bottom";

const Tarifs: React.FC = () => {
  const [ref1, inView1] = useInView({});
  const [ref2, inView2] = useInView({});
  const [ref3, inView3] = useInView({});

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    // Nettoyage de l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isOnTop, setIsOnTop] = useState(false);
  useLayoutEffect(() => {
    if (!isOnTop) {
      window.scrollTo(0, 0);
      setIsOnTop(true);
    }
  }, [isOnTop]);
  return (
    <div>
      <Navbar />
      <Plain
        ref1={ref1}
        ref2={ref2}
        ref3={ref3}
        inView1={inView1}
        inView2={inView2}
        inView3={inView3}
        title={"Tarifs"}
        textPath={"/md/tarifs.md"}
      ></Plain>

      <Bottom />
    </div>
  );
};

export default Tarifs;
