import anime from "animejs";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Plus } from "../svg/plus.svg"; // Import du fichier SVG
type Position = "absolute" | "relative" | "fixed" | "static" | "sticky";

type UserSelect =
  | "none"
  | "auto"
  | "text"
  | "contain"
  | "all"
  | "element"
  | "inherit"
  | "initial"
  | "unset";
const More = ({
  ref1,
  inView1,
}: {
  ref1: React.RefObject<any>;
  inView1: boolean;
}) => {
  const [isEnCours, setIsEnCours] = useState(false);
  const [lauchAnim, setLaunchAnim] = useState(false);
  useEffect(() => {
    if (inView1) {
      setLaunchAnim(true);
    }
  }, [inView1]);

  const styles = {
    container: {
      zIndex: 2,
      width: "0px",
      height: "0px",
      borderRadius: "50px",
      // backgroundColor: "rgba(183, 244, 195,1)",
      backgroundColor: "#2d6a4d",

      position: "absolute" as Position | undefined,
      // bottom: "100px",
      top: "50%",
      right: "-50%",
      transform: "translateX(-50%) translateY(-50%)",
      overflow: "hidden",
      alignItems: "center",
      display: "flex",
      justifyContent: "left",
      userSelect: "none" as UserSelect,
    },

    background: {
      cursor: "pointer",
      width: "0px",
      height: "0px",
      borderRadius: "50px",
      // backgroundColor: "rgba(200, 200, 200, 0.65)",
      backgroundColor: "rgba(183, 244, 195, 0.65)",

      position: "sticky" as Position | undefined,
      // bottom: "50px",
      // marginTop: "100px",
      alignItems: "center",
      display: "flex",
      justifyContent: "left",
      backdropFilter: "blur(5px)",
      userSelect: "none" as UserSelect,
    },
    text: {
      fontFamily: "Inter",
      fontSize: window.innerWidth > 1090 ? "22px" : "20px",
      fontWeight: "600",
      textAlign: "left" as "left",
      alignItems: "center",
      padding: "25px",
      opacity: 0,
      color: "#2a533f",
      overflow: "hidden",
      userSelect: "none" as UserSelect,
    },
    plus: {
      opacity: 0,
      height: 39,
      whidth: 39,
      padding: "3px",
      display: "block",
      alignContent: "center",
      color: "#2d6a4d",
      userSelect: "none" as UserSelect,
    },
  };
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const plusRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    async function anim() {
      setIsEnCours(true);
      // await new Promise((resolve) => setTimeout(resolve, 1000));

      anime({
        targets: ref1.current,
        width: ["0px", "55px"],
        height: ["0px", "55px"],
        duration: 600,
        easing: "easeInOutExpo",
      });
      // await new Promise((resolve) => setTimeout(resolve, 100));

      anime({
        targets: containerRef.current,
        width: ["0px", "80px", "44px"],
        height: ["0px", "80px", "44px"],
        delay: 250,
        duration: 600,
        easing: "easeInOutQuad",
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));

      anime({
        targets: plusRef.current,
        width: "39px",
        height: "39px",
        opacity: 1,
        easing: "easeOutExpo",
        duration: 800,
      });
      await new Promise((resolve) => setTimeout(resolve, 800));
      anime({
        targets: ref1.current,
        width: "240px",
        height: "55px",
        borderWidth: "1px",
        easing: "easeOutExpo",
        duration: 500,
      });
      anime({
        targets: containerRef.current,

        easing: "easeOutExpo",
        duration: 500,
        left: "205px",
      });
      await new Promise((resolve) => setTimeout(resolve, 500));

      anime({
        targets: textRef.current,
        opacity: 1,
        duration: 1000,
        easing: "easeOutQuad",
      });
      setIsEnCours(false);
    }
    async function reset() {
      // await new Promise((resolve) => setTimeout(resolve, 4000));
      setIsEnCours(true);

      anime({
        targets: ref1.current,
        width: "0px",
        height: "0px",
        duration: 1,
      });
      anime({
        targets: containerRef.current,
        width: "0px",
        height: "0px",
        left: "50%",
        duration: 1,
      });
      anime({
        targets: textRef.current,
        opacity: 0,
        duration: 1,
      });
      anime({
        targets: plusRef.current,
        width: "39px",
        height: "39px",
        opacity: 0,
        easing: "easeOutExpo",
        duration: 1,
      });
      setIsEnCours(false);
    }
    if (inView1) {
      if (!isEnCours) {
        anim();
      }
    } else {
      if (!isEnCours) {
        reset();
      }
    }
  }, [lauchAnim]);

  return (
    <div
      style={{
        position: "sticky",
        bottom: 10,
        width: "90%",
        height: "55px",
        display: "flex",
        justifyContent: "center",
        // marginBottom: 10,
        borderRadius: 100,
        padding: 10,
        userSelect: "none",
        zIndex: 150,
        margin: "15px auto",
      }}
    >
      <div className="background" style={styles.background} ref={ref1}>
        <div style={styles.container} className="container" ref={containerRef}>
          <div className="plusButton">
            <Plus className="plus" style={styles.plus} ref={plusRef} />
          </div>
        </div>
        <p style={styles.text} className="viewMore" ref={textRef}>
          En savoir plus
        </p>
      </div>
    </div>
  );
};

export default More;
