// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles.css";
// Définir les types pour les props
interface SwiperComponentProps {
  images: string[];
}

// Styles pour les cartes
const cardStyle: React.CSSProperties = {
  height: window.innerWidth < 500 ? "50vh" : "60vh",
  borderRadius: "30px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white", // Couleur du texte
  fontSize: "24px", // Taille du texte
  transition: "transform 0.8s ease", // Animation de transition
};

const SwiperComponent: React.FC<SwiperComponentProps> = ({ images }) => {
  return (
    <div
      style={{
        // padding: 20,
        overflow: "hidden",
        // background: "red",
        borderRadius: 30,
        width: window.innerWidth < 500 ? "calc(100% - 20px)" : "90%",
        maxWidth: "1500px",
        margin: "0 auto",
      }}
    >
      <Swiper
        className="mySwiper"
        spaceBetween={20}
        grabCursor={true}
        // pagination={{
        //   dynamicBullets: true,
        // }}
        pagination={true}
        navigation={true}
        modules={[Pagination, Navigation]}
        slidesPerView={
          window.innerWidth < 500 ? "1" : window.innerWidth < 900 ? "2" : "2"
        }
        // slidesPerView={"auto"}
        initialSlide={0}
        // centeredSlides={true} // Centrer les slides

        style={{ overflow: "visible" }} // Permet d'afficher la carte active plus large
      >
        {images.map((image, index) => (
          <SwiperSlide
            key={index}
            style={{
              // width: index === activeIndex ? "50%" : "25%", // Largeur dynamique
              // width:
              //   index === activeIndex
              //     ? "50%"
              //     : activeIndex + 1
              //     ? "20%"
              //     : activeIndex + 2
              //     ? "5%"
              //     : "1%", // Largeur dynamique

              transition: "width 0.3s ease", // Animation de transition
            }}
          >
            <div
              style={{
                ...cardStyle,
                backgroundImage: `url(${image})`,
              }}
            ></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperComponent;
