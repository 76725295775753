import { ReactComponent as Facebook } from "../svg/facebook.svg"; // Import du fichier SVG
import { Link } from "react-router-dom";

const Bottom = () => {
  type TextAlign =
    | "left"
    | "right"
    | "center"
    | "justify"
    | "initial"
    | "inherit";
  type FlexWrap = "wrap";
  const styles = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      // padding: "20px",
      flexWrap: "wrap" as FlexWrap,
      backgroundColor: "#2d6a4d",
      // paddingBottom: 50,
    },
    column: {
      // flex: 1,
      flex: "1 1 480px",
      width: "100%",
      minWidth: "200px",
      marginBottom: 30,
    },
    title: {
      marginTop: "50px",
      // marginBottom: window.innerWidth < 690 ? 40 : 80,
      textAlign: "center" as TextAlign,
      fontFamily: "MADE_Gentle",

      fontSize:
        window.innerWidth < 500
          ? "35px"
          : window.innerWidth < 500
          ? "40px"
          : window.innerWidth < 600
          ? "50px"
          : window.innerWidth < 1000
          ? "60px"
          : window.innerWidth < 1340
          ? "60px"
          : "80px",
      color: "white",
      width: "100vw",
    },
    subTitle: {
      // marginTop:
      //   window.innerWidth < 690 ? 20 : window.innerWidth < 1300 ? 100 : 120,
      marginBottom: window.innerWidth < 690 ? 40 : 80,
      textAlign: "center" as TextAlign,
      fontFamily: "MADE_Gentle",

      fontSize:
        window.innerWidth < 500
          ? "25px"
          : window.innerWidth < 500
          ? "30px"
          : window.innerWidth < 600
          ? "35x"
          : window.innerWidth < 1000
          ? "45px"
          : window.innerWidth < 1340
          ? "50px"
          : "65px",
      color: "white",
      width: "100vw",
    },
    text: {
      fontSize:
        window.innerWidth < 1220
          ? "19px"
          : window.innerWidth < 1360
          ? "20px"
          : window.innerWidth < 1700
          ? "22px"
          : "24px",
      color: "black",
      textAlign: window.innerWidth < 1080 ? "center" : ("center" as TextAlign),
      fontFamily: "Inter",
      fontWeight: "light",
      paddingLeft: window.innerWidth < 800 ? 5 : 40,
      paddingRight: window.innerWidth < 800 ? 5 : 40,
      // height: "55vh",
    },
    image: {
      width: "100%",
      height: "auto",
      borderRadius: 30,
    },

    textContainer: {
      // textAlign: "left" as TextAlign,
      alignItems: "center",
      display: "flex",
      // flexDirection: "row" as FlexDirection,
      fontSize:
        window.innerWidth < 1220
          ? "18px"
          : window.innerWidth < 1220
          ? "19px"
          : window.innerWidth < 1360
          ? "20px"
          : window.innerWidth < 1700
          ? "22px"
          : "24px",
      color: "black",
      // textAlign: "center" as TextAlign,
      fontFamily: "Inter",
      fontWeight: "light",
      paddingLeft: window.innerWidth < 800 ? 5 : 40,
      paddingRight: window.innerWidth < 800 ? 5 : 40,
    },

    imageContainer: {
      // backgroundColor: "red",
      borderRadius: 30,
      maxHeight: "60vh",
      padding: 10,
      // marginRight: window.innerWidth < 500 ? 5 : 70,
      // marginLeft: window.innerWidth < 500 ? 5 : 70,
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title} className="title">
        Stéphanie Richard
      </h1>
      <h2 style={styles.subTitle}>Sur rendez-vous</h2>
      <div style={{ ...styles.column, ...styles.textContainer }}>
        <div
          style={{
            maxWidth: "800px",
            maxHeight: "100%",
            position: "relative",
            left: "50%",
            translate: "-50%",
            fontFamily: "Made_Gentle",
            color: "white",
          }}
        >
          {/* <MapContainer
            center={[51.505, -0.09]}
            zoom={13}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[51.505, -0.09]}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          </MapContainer> */}
          {/* <MapContainer
            center={[51.505, -0.09]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ backgroundColor: "red" }}
          >
            <TileLayer
              url="https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}@2x.png?key=huVX7hkzwPTJEeV6iaEd"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </MapContainer> */}
          <h2 style={{ textAlign: "center", marginBottom: 10 }}>Adresse</h2>
          <p style={{ textAlign: "center" }}>2 rue Odoul</p>
          <p style={{ textAlign: "center" }}>59470 Wormhout</p>
        </div>
      </div>
      <div style={{ ...styles.column, ...styles.textContainer }}>
        <div
          style={{
            maxWidth: "800px",
            maxHeight: "100%",
            position: "relative",
            left: "50%",
            translate: "-50%",
            fontFamily: "Made_Gentle",
            color: "white",
          }}
        >
          <h2 style={{ textAlign: "center", marginBottom: 10 }}>Contact</h2>

          <p style={{ textAlign: "center" }}>
            <a
              href="tel:+33631950566"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
            >
              Tel : 06 31 95 05 66
            </a>
          </p>

          <p style={{ textAlign: "center" }}>
            <a
              href="mailto:stephanierichard.sophrologue@gmail.com"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
            >
              stephanierichard.sophrologue@gmail.com
            </a>
          </p>
        </div>
      </div>
      <div
        style={{
          flexBasis: "100%",
          alignItems: "center",
          position: "relative",
          marginBottom: 20,
        }}
      >
        <a
          href="https://www.facebook.com/people/St%C3%A9phanie-Richard-Sophrologue/100067652604018/"
          target="_blank"
          rel="noreferrer"
        >
          <Facebook
            fill="white"
            height={60}
            width={60}
            style={{
              margin: "auto",
              position: "relative",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          />
        </a>
      </div>

      <Link
        to="/politique-confidentialite"
        style={{
          color: "white",
          width: "50vw",
          textAlign: "center",
          marginBottom: 10,
          textDecoration: "none",
          fontFamily: "Inter",
          fontSize: 15,
        }}
      >
        politique de confidentialité
      </Link>
      <Link
        to="/mentions-legales"
        style={{
          color: "white",
          width: "50vw",
          textAlign: "center",
          marginBottom: 10,
          textDecoration: "none",
          fontFamily: "Inter",
          fontSize: 15,
        }}
      >
        mentions légales
      </Link>
    </div>
  );
};

export default Bottom;
