import anime from "animejs";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../../pages/custom-font.css";
import Menu from "../lottie/burger_menu.json";
import Lottie, { LottieRefCurrentProps } from "lottie-react";

type UserSelect =
  | "none"
  | "auto"
  | "text"
  | "contain"
  | "all"
  | "element"
  | "inherit"
  | "initial"
  | "unset";

const Navbar = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    // Nettoyage de l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles = {
    navBar: {
      zIndex: 200,
      position: "fixed" as "fixed" | undefined,
      top: "30px",
      left: "50%",
      transform: "translate(-50%, -50%)",
      margin: "auto",
      marginTop: "20px",
      // width: "80vw",
      width: "0px",
      // height: "60px",
      height: "0px",
      borderRadius: 50,
      backgroundColor: "rgba(183, 244, 195, 0.65)",
      backdropFilter: "blur(5px)",
      alignItems: "center",
      display: "flex",
      justifyontent: "space-between",
      userSelect: "none" as UserSelect | undefined,
      // filter: "drop-shadow(0px 1px 5px #9bc4a8)",
      // WebkitFilter: ` blur(${10}px)`,
      // padding: "0px 20px",
      "-webkit-backdrop-filter": " blur(5px)",
    },
    link: {
      opacity: 0,
      flex: 1,
      color: "#2a533f",
      textDecoration: "none",
      fontFamily: "Inter",
      fontWeight: "bold",
      fontSize: "17px",
      width: "inherit",
    },
    menuLink: {
      display: "block",
      color: "white",
      fontSize: "35px",
      padding: "15px",
      fontFamily: "Inter",
      fontWeight: "bold",
      marginTop: "10px",
      textDecoration: "none",
    },
  };
  const menuDotRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    anime({
      targets: ".navbar",
      // width: "80vw",
      height: ["0px", "75px", "55px"],
      width: ["0px", "75px", "55px"],
      duration: 800,
      easing: "easeInOutQuad",
      complete: function () {
        anime({
          targets: ".navbar",
          width: "82vw",
          duration: 500,
          delay: 300,
          easing: "easeOutExpo",
          complete: function () {
            anime({
              targets: ".navbar",
              width: "80vw",
              duration: 400,
              easing: "easeOutQuad",
              complete: function () {
                anime({
                  targets: ".nav-link",
                  opacity: 1,
                  easing: "easeInOutQuad",
                  duration: 700,
                });
              },
            });
          },
        });
      },
    });
    anime({
      targets: menuDotRef.current,
      // height: ["0px", "75px", "60px"],
      // width: ["0px", "75px", "60px"],
      scale: ["0", "1.2", "1"],
      duration: 800,
      easing: "easeInOutQuad",
    });
  }, []);
  const [load, setLoaded] = useState(false);
  const [menu, setMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const linkRef = useRef<HTMLDivElement | null>(null);
  const burgerMenuRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    const animation = burgerMenuRef.current;
    animation?.pause();
    if (menuRef.current && menu && load) {
      anime({
        targets: menuRef.current,
        // translateX: ["100%", "0%"], // Déplace la div de 100% à 0% sur l'axe X
        width: ["0vw", "100vw"],
        duration: 500, // Durée de l'animation en millisecondes
        easing: "easeOutExpo", // Type d'animation
      });
      anime({
        targets: linkRef.current,
        opacity: 1,
        easing: "easeInOutQuad",
        duration: 700,
      });
      const animation = burgerMenuRef.current;

      // Jouer de la frame 0 à la frame 90
      if (animation) {
        // animation.goToAndStop(0, true);
        // animation.play();
        animation.playSegments([0, 90], true);
      }

      // setTimeout(() => {
      //   BurgerMenuRef.goToAndStop(90, true);
      //   animation.stop();
      //   // Jouer de la frame 90 à la frame 180 après un délai
      //   setTimeout(() => {
      //     animation.play();
      //   }, 100); // Délai avant de commencer la deuxième partie
      // }, animation.getDuration() * 1000); // Durée de l'animation de 0 à 90
    }
    if (menuRef.current && !menu && load) {
      const animation = burgerMenuRef.current;

      if (animation) {
        // animation.goToAndStop(0, true);
        // animation.play();
        animation.playSegments([90, 180], true);
      }
      anime({
        targets: menuRef.current,
        // translateX: ["0%", "100%"], // Déplace la div de 100% à 0% sur l'axe X
        width: ["100vw", "0vw"],

        duration: 700, // Durée de l'animation en millisecondes
        easing: "easeOutExpo", // Type d'animation
      });
      anime({
        targets: linkRef.current,
        opacity: 0,
      });
    }
  }, [menu, load]);

  return (
    <>
      {window.innerWidth > 690 && (
        <div style={styles.navBar} className="navbar" id="navbar">
          <Link to="/#" style={styles.link} className="nav-link" id="test">
            Accueil
          </Link>
          <Link to="/sophrologie" style={styles.link} className="nav-link">
            La sophrologie
          </Link>
          <Link
            to="/accompagnement-parental"
            style={styles.link}
            className="nav-link"
          >
            Accompagnement parental
          </Link>

          <Link to="/tarifs" style={styles.link} className="nav-link">
            Tarifs
          </Link>
        </div>
      )}

      {window.innerWidth <= 690 && (
        <>
          <div
            ref={menuDotRef}
            style={{
              backgroundColor: "rgba(183, 244, 195, 0.65)",
              backdropFilter: "blur(5px)",
              height: 65,
              width: 65,
              position: "fixed",
              top: "20px",
              right: "20px",
              zIndex: 300,
              borderRadius: "50%",
              alignContent: "center",
            }}
            onClick={() => {
              setMenu(!menu);
              setLoaded(true);
            }}
          >
            <Lottie
              animationData={Menu}
              width={50}
              color="white"
              lottieRef={burgerMenuRef}
              // ref={mouseRef}
              style={{
                opacity: 1,
                height: 40,
                maxHeight: 40,
                margin: "auto",
              }}
              loop={false}
              autoPlay={false}
            />
          </div>

          <div
            ref={menuRef}
            style={{
              top: 0,
              right: 0,
              background: "#2d6a4d",
              width: "0vw",
              height: "100vh",
              zIndex: 299,
              position: "fixed",
              overflow: "hidden",
              textAlign: "center",
              display: "flex", // Utilisez Flexbox
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div ref={linkRef} style={{ opacity: 0 }}>
              <Link
                to="/#"
                style={styles.menuLink}
                onClick={() => {
                  setMenu(false);
                }}
              >
                Accueil
              </Link>
              <Link
                to="/sophrologie"
                style={styles.menuLink}
                onClick={() => {
                  setMenu(false);
                }}
              >
                La sophrologie
              </Link>
              <Link
                to="/accompagnement-parental"
                style={styles.menuLink}
                onClick={() => {
                  setMenu(false);
                }}
              >
                Accompagnement parental
              </Link>

              <Link
                to="/tarifs"
                style={styles.menuLink}
                onClick={() => {
                  setMenu(false);
                  anime({
                    targets: menuRef.current,
                    // translateX: ["0%", "100%"], // Déplace la div de 100% à 0% sur l'axe X
                    width: ["100vw", "0vw"],

                    duration: 700, // Durée de l'animation en millisecondes
                    easing: "easeOutExpo", // Type d'animation
                  });
                }}
              >
                Tarifs
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;
