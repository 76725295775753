import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import backgroundImage from "../assets/img/background.webp";
// import cabinetImg from "../assets/img/final.webp";

import "./custom-font.css";
import TextLeft from "../assets/components/textLeft";
import anime from "animejs";
import Navbar from "../assets/components/navbar";
import { useInView } from "@react-spring/web";
import Sound from "../assets/components/sound";
import Lottie from "lottie-react";
import Mouse from "../assets/lottie/mouse.json";
import { useParallax } from "react-scroll-parallax";
import WhoAmI from "../assets/components/whoami";
import SwiperComponent from "../assets/components/swiper";
import Bottom from "../assets/components/bottom";

type TextAlign =
  | "left"
  | "right"
  | "center"
  | "justify"
  | "initial"
  | "inherit";
type UserSelect =
  | "none"
  | "auto"
  | "text"
  | "contain"
  | "all"
  | "element"
  | "inherit"
  | "initial"
  | "unset";

const HomePage: React.FC = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [ref1, inView1] = useInView({});
  const [ref2, inView2] = useInView({});
  const [ref3, inView3] = useInView({});
  const [ref4, inView4] = useInView({});
  const [ref5, inView5] = useInView({});
  const [ref6, inView6] = useInView({});
  // const [ref7, inView7] = useInView({});
  // const [ref8, inView8] = useInView({});
  // const [ref9, inView9] = useInView({});
  const [ref10, inView10] = useInView({});
  const [ref11, inView11] = useInView({});

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    // Nettoyage de l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isOnTop, setIsOnTop] = useState(false);
  useLayoutEffect(() => {
    if (!isOnTop) {
      window.scrollTo(0, 0);
      setIsOnTop(true);
    }
  });

  const styles = {
    container: {
      // height: "100vh",
      width: "100vw",
      // background: "linear-gradient(to bottom, #194D35, #2E6C4E)",
      // background: "red",

      // overflow: "hidden",
      textAlign: "center" as TextAlign,
      alignItems: "center",
    },
    imgLayer: {
      zIndex: 1,
      // position: "absolute" as "absolute" | undefined,
      // top: "50%",
      // left: "50%",
      // transform: " scale(1.1)",
      // maxWidth: "100%",
      // overflow: "hidden",
      height: "100vh",
      width: "100vw",
      objectFit: "cover" as "cover" | undefined,
      userSelect: "none" as UserSelect | undefined,
    },
    textLayer: {
      zIndex: 100,
      position: "absolute" as "absolute" | undefined,
      top: "50%",
      left: "50%",
      userSelect: "none" as UserSelect | undefined,
      transform: "translate(-50%, -50%)",
      textAlign: "center" as TextAlign,
      fontFamily: "MADE_Gentle",
      // fontSize:
      //   window.innerWidth < 690
      //     ? "30px"
      //     : window.innerWidth < 780
      //     ? "35px"
      //     : window.innerWidth < 880
      //     ? "40px"
      //     : window.innerWidth < 1080
      //     ? "45px"
      //     : window.innerWidth < 1340
      //     ? "55px"
      //     : "70px",
      color: "white",
      width: "90vw",
      opacity: 0,
    },

    navBar: {
      zIndex: 100,
      position: "absolute" as "absolute" | undefined,
      left: "50%",
      transform: "translate(-50%, 0%)",
      margin: "auto",
      marginTop: "20px",
      width: "80vw",
      height: "60px",
      borderRadius: 50,
      backgroundColor: "rgba(183, 244, 195, 0.65)",
      alignItems: "center",
      display: "flex",
      justifyontent: "space-between",
      userSelect: "none" as UserSelect | undefined,
    },
    link: {
      flex: 1,
      color: "#2a533f",
      textDecoration: "none",
      fontFamily: "Inter",
      fontWeight: "bold",
      fontSize: "20px",
      width: "inherit",
    },
    page2: {
      minHeight: "100vh",
      width: "100vw",
      backgroundColor: "white",
    },
  };

  const mouseRef = useRef(null);
  useEffect(() => {
    if (imageLoaded) {
      setTimeout(() => {
        anime({
          targets: ".image",
          // translateX: "-50%",
          translateY: ["-150%", "0"],
          duration: 1000,
          easing: "spring(1, 80, 15, 0)",
        });
        anime({
          targets: ".titleContainer",
          opacity: [0, 1],
          // translateY: ["1.1em", 0],
          duration: 800,
          delay: 1000,
          easing: "easeInOutQuad",
          complete: () => {
            anime({
              targets: ".mouse",
              opacity: [0, 1],
              duration: 1000,
              easing: "easeInOutQuad",
            });
          },
        });
      }, 300);
    }
  }, [imageLoaded]);

  const parallaxImg = useParallax<HTMLImageElement>({
    speed: 15,
  });
  const parallaxLottie = useParallax<HTMLDivElement>({
    speed: 20,
  });
  const parallaxWhoAmI = useParallax<HTMLDivElement>({
    speed: 0,
  });
  // const parallaxText = useParallax<HTMLDivElement>({
  //   speed: 5,
  // });
  const images = [
    "./cabinet1.webp",
    "./cabinet2.webp",
    "./salledattente.webp",
    "./cabinet.webp",
  ];

  return (
    <div>
      <Navbar />

      <div style={styles.container}>
        {/* <Parallax translateY={[-20, 10]}> */}
        <img
          ref={parallaxImg.ref}
          className="image"
          src={backgroundImage}
          alt="background"
          style={styles.imgLayer}
          onLoad={() => {
            setImageLoaded(true);
          }}
        />
        {/* </Parallax> */}
        {/* <Parallax translateY={[0, -10]}> */}
        <div
          style={styles.textLayer}
          // ref={parallaxText.ref}
          className="titleContainer"
        >
          <h1
            className="titleMain"
            style={{
              fontSize:
                window.innerWidth < 350
                  ? "30px"
                  : window.innerWidth < 400
                  ? "35px"
                  : window.innerWidth < 450
                  ? "38px"
                  : window.innerWidth < 500
                  ? "40px"
                  : window.innerWidth < 550
                  ? "40px"
                  : window.innerWidth < 580
                  ? "45px"
                  : window.innerWidth < 700
                  ? "50px"
                  : window.innerWidth < 880
                  ? "65px"
                  : window.innerWidth < 1080
                  ? "75px"
                  : window.innerWidth < 1340
                  ? "80px"
                  : "100px",
            }}
          >
            Stéphanie Richard
          </h1>
          <h1
            className="titleMain"
            style={{
              fontSize:
                window.innerWidth < 350
                  ? "45px"
                  : window.innerWidth < 400
                  ? "55px"
                  : window.innerWidth < 450
                  ? "62px"
                  : window.innerWidth < 500
                  ? "70px"
                  : window.innerWidth < 550
                  ? "78px"
                  : window.innerWidth < 580
                  ? "85px"
                  : window.innerWidth < 700
                  ? "90px"
                  : window.innerWidth < 880
                  ? "110px"
                  : window.innerWidth < 1080
                  ? "130px"
                  : window.innerWidth < 1340
                  ? "150px"
                  : "200px",
            }}
          >
            sophrologue
          </h1>
        </div>
        {/* </Parallax> */}
        <div
          ref={parallaxLottie.ref}
          style={{
            position: "absolute",
            left: "50%",
            bottom: "150px",
            transform: "translateX(-50%)",
          }}
        >
          <Lottie
            animationData={Mouse}
            width={50}
            color="white"
            lottieRef={mouseRef}
            // ref={mouseRef}
            className="mouse"
            style={{ opacity: 0, transform: "translateX(-50%)", width: "80px" }}
          />
        </div>
      </div>
      <div ref={parallaxWhoAmI.ref}>
        <WhoAmI
          ref1={ref4}
          ref2={ref5}
          ref3={ref6}
          inView1={inView4}
          inView2={inView5}
          inView3={inView6}
        />
      </div>

      {/* <Services
        ref1={ref4}
        ref2={ref5}
        ref3={ref6}
        inView1={inView4}
        inView2={inView5}
        inView3={inView6}
        title={"La sophrologie"}
      /> */}

      <TextLeft
        ref1={ref1}
        ref2={ref2}
        ref3={ref3}
        inView1={inView1}
        inView2={inView2}
        inView3={inView3}
        title={"Ce que je vous propose"}
        // text={text.services}
        textPath={"/md/services.md"}
        img={"/cabinet.webp"}
      />
      {/* <div style={styles.page2}> */}
      <Sound ref1={ref10} ref2={ref11} inView1={inView10} inView2={inView11} />
      {/* </div> */}
      <div style={{ marginBottom: 50 }}>
        <h1
          style={{
            marginTop:
              window.innerWidth < 690
                ? 20
                : window.innerWidth < 1300
                ? 100
                : 120,
            marginBottom: 50,
            textAlign: "center" as TextAlign,
            fontFamily: "MADE_Gentle",

            fontSize:
              window.innerWidth < 500
                ? "45px"
                : window.innerWidth < 500
                ? "50px"
                : window.innerWidth < 600
                ? "60px"
                : window.innerWidth < 1000
                ? "70px"
                : window.innerWidth < 1340
                ? "70px"
                : "90px",
            color: "#2d6a4d",
            width: "100vw",
          }}
        >
          Le cabinet
        </h1>
        <SwiperComponent images={images} />
      </div>
      <Bottom />
    </div>
  );
};

export default HomePage;
