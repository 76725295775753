import React, { useEffect, useLayoutEffect, useState } from "react";
import TextLeft from "../assets/components/textLeft";
import TextRight from "../assets/components/textRight";
import { useInView } from "@react-spring/web";
import Navbar from "../assets/components/navbar";
import Plain from "../assets/components/plain";
import Bottom from "../assets/components/bottom";

const Accompagnement: React.FC = () => {
  const [ref1, inView1] = useInView({});
  const [ref2, inView2] = useInView({});
  const [ref3, inView3] = useInView({});
  // const [ref4, inView4] = useInView({});
  // const [ref5, inView5] = useInView({});
  // const [ref6, inView6] = useInView({});
  const [ref7, inView7] = useInView({});
  const [ref8, inView8] = useInView({});
  const [ref9, inView9] = useInView({});
  const [ref10, inView10] = useInView({});
  const [ref11, inView11] = useInView({});
  const [ref12, inView12] = useInView({});
  const [ref13, inView13] = useInView({});
  const [ref14, inView14] = useInView({});
  const [ref15, inView15] = useInView({});

  const styles = {
    page2: {
      minHeight: "85vh",
      width: "100vw",
      backgroundColor: "white",
    },
  };
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    // Nettoyage de l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isOnTop, setIsOnTop] = useState(false);
  useLayoutEffect(() => {
    if (!isOnTop) {
      window.scrollTo(0, 0);
      setIsOnTop(true);
    }
  });
  return (
    <div>
      <Navbar />
      <div style={{ height: 60 }}></div>

      <Plain
        ref1={ref1}
        ref2={ref2}
        ref3={ref3}
        inView1={inView1}
        inView2={inView2}
        inView3={inView3}
        title={"Accompagnement parental"}
        textPath={"/md/accompagnement.md"}
      ></Plain>

      {/* <div style={styles.page2}>
        <TextLeft
          ref1={ref4}
          ref2={ref5}
          ref3={ref6}
          inView1={inView4}
          inView2={inView5}
          inView3={inView6}
          title={"Déroulement d'une séance"}
          textPath={"/md/deroulement_accompagnement.md"}
          img={"/accompagnement.webp"}
          // morePath="/md/more/sophrologie_more.md"
        />
      </div> */}

      <div style={styles.page2}>
        <TextRight
          ref1={ref7}
          ref2={ref8}
          ref3={ref9}
          inView1={inView7}
          inView2={inView8}
          inView3={inView9}
          title={"Pour quoi ?"}
          img={"/accompagnement_pourquoi.webp"}
          textPath={"/md/accompagnement_pourquoi.md"}
          morePath="/md/more/accompagnement_pourquoi_more.md"
        />
      </div>
      <TextLeft
        ref1={ref10}
        ref2={ref11}
        ref3={ref12}
        inView1={inView10}
        inView2={inView11}
        inView3={inView12}
        title={"Pour qui ?"}
        img={"/accompagnement.webp"}
        textPath={"/md/accompagnement_pourqui.md"}
        // morePath="/md/more/pourqui_more.md"
      />
      <Plain
        ref1={ref13}
        ref2={ref14}
        ref3={ref15}
        inView1={inView13}
        inView2={inView14}
        inView3={inView15}
        title={"Combien de séances ?"}
        textPath={"/md/accompagnement_nombre.md"}
      ></Plain>
      <div style={{ height: 100 }}></div>
      <Bottom />
    </div>
  );
};

export default Accompagnement;
