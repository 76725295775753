import React, { useEffect, useState } from "react";
import anime from "animejs";
import Markdown from "react-markdown";
import { Sheet } from "react-modal-sheet";
import sheetImage from "../img/background.webp";
import { ReactComponent as Close } from "../svg/close.svg"; // Import du fichier SVG
import More from "./more";

interface PlainProps {
  ref1: React.RefObject<any>;
  ref2: React.RefObject<any>;
  ref3: React.RefObject<any>;
  inView1: boolean;
  inView2: boolean;
  inView3: boolean;
  title: string;
  textPath: string;
  morePath?: string;
}

const Plain: React.FC<PlainProps> = ({
  ref1,
  ref2,
  ref3,
  inView1,
  inView2,
  inView3,
  title,
  textPath,
  morePath,
}) => {
  type TextAlign =
    | "left"
    | "right"
    | "center"
    | "justify"
    | "initial"
    | "inherit";

  const styles = {
    title: {
      marginTop:
        window.innerWidth < 690 ? 20 : window.innerWidth < 1300 ? 100 : 120,
      marginBottom: window.innerWidth < 690 ? 40 : 80,
      textAlign: "center" as TextAlign,
      fontFamily: "MADE_Gentle",

      fontSize:
        window.innerWidth < 500
          ? "45px"
          : window.innerWidth < 500
          ? "50px"
          : window.innerWidth < 600
          ? "60px"
          : window.innerWidth < 1000
          ? "70px"
          : window.innerWidth < 1340
          ? "70px"
          : "90px",
      color: "#2d6a4d",
      width: "100vw",
      opacity: 0,
    },
    text: {
      fontSize:
        window.innerWidth < 1220
          ? "19px"
          : window.innerWidth < 1360
          ? "20px"
          : window.innerWidth < 1700
          ? "21px"
          : "22px",
      color: "black",
      textAlign: "center" as TextAlign,
      fontFamily: "Inter",
      fontWeight: "light",
      paddingLeft: window.innerWidth < 500 ? 5 : 30,
      paddingRight: window.innerWidth < 500 ? 5 : 30,
      width: window.innerWidth < 800 ? " 95%" : "70%",
      margin: "0 auto",
    },
    moreContainer: {
      // textAlign: "left" as TextAlign,

      // flexDirection: "row" as FlexDirection,
      fontSize:
        window.innerWidth < 1220
          ? "16px"
          : window.innerWidth < 1220
          ? "17px"
          : window.innerWidth < 1360
          ? "20px"
          : window.innerWidth < 1700
          ? "21px"
          : "22px",
      color: "black",
      // textAlign: "center" as TextAlign,
      fontFamily: "Inter",
      fontWeight: "light",
    },
    sheetContainer: {
      width: window.innerWidth < 800 ? "95vw" : "80vw",
      margin: "auto",
      left: "50%",
      translateX: "-50%",
      borderRadius: "30px 30px 0 0",
      overflow: "hidden",
    },
    imageContainer: {
      // backgroundColor: "red",
      borderRadius: 30,
      maxHeight: "60vh",
      padding: 10,
      // marginRight: window.innerWidth < 500 ? 5 : 70,
      // marginLeft: window.innerWidth < 500 ? 5 : 70,
    },
  };

  const [animTitle, setAnimTitle] = useState(false);
  useEffect(() => {
    if (inView1) {
      setAnimTitle(true);
    }
  }, [inView1]);

  useEffect(() => {
    if (animTitle) {
      anime({
        targets: ref1.current,
        translateY: ["60px", "20px"],
        opacity: [0, 1],
        // scale: [0.8, 1],
        duration: 1000,
        easing: "easeInOutQuad",
      });
    }
  }, [animTitle]);

  const [isOpenSheet, setOpenSheet] = useState(false);
  const [markdown, setMarkdown] = useState("");
  const [markdownMore, setMarkdownMore] = useState("");

  useEffect(() => {
    const fetchMarkdown = async () => {
      const response = await fetch(textPath);
      const text = await response.text();
      setMarkdown(text);
      if (morePath) {
        const responseMore = await fetch(morePath);
        const textMore = await responseMore.text();
        setMarkdownMore(textMore);
      }
    };

    fetchMarkdown();
  }, []);
  const closeModal = () => setOpenSheet(false);

  return (
    <div>
      <h1 style={styles.title} ref={ref1}>
        {title}
      </h1>

      <div style={styles.text} className="markdown">
        <Markdown>{markdown}</Markdown>
      </div>

      {morePath && (
        <div
          onClick={() => {
            setOpenSheet(true);
          }}
          style={{
            position: "sticky",
            bottom: 10,
            width: "fit-content",
            // height: "55px",
            display: "flex",
            justifyContent: "center",
            // marginBottom: 10,
            borderRadius: 100,
            // padding: 10,
            userSelect: "none",
            zIndex: 150,
            margin: "15px auto",
          }}
        >
          <More ref1={ref3} inView1={inView3} />
        </div>
      )}

      {morePath && (
        <Sheet
          isOpen={isOpenSheet}
          onClose={() => closeModal()}
          disableDrag={/^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          )}
        >
          <Sheet.Container style={styles.sheetContainer}>
            <Sheet.Header />
            <button
              className="close-button"
              onClick={closeModal}
              style={{
                width: "50px",
                height: "50px",
                zIndex: 100,
                borderRadius: "50%",
                position: "absolute",
                top: "20px",
                right: "20px",
                border: "none",
                background: "rgba(183, 244, 195, 0.65)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Close width="24" height="24" fill="#2a533f" />
            </button>
            <Sheet.Content style={styles.moreContainer}>
              <Sheet.Scroller draggableAt="both">
                <img
                  src={sheetImage}
                  alt="fond"
                  style={{
                    userSelect: "none",
                    // borderRadius: "30px 30px 0 0",
                    width: "100%",
                    height: "35%",
                    objectFit: "cover",
                    objectPosition: "50% 10%",
                    // position: "absolute",
                    top: 0,
                  }}
                />
                <div style={{ padding: 30 }} className="markdownMore">
                  <Markdown>{markdownMore}</Markdown>
                </div>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop
            style={{
              backgroundColor: " rgba(255, 255, 255, 0.2)",
              backdropFilter: "blur(10px)",
            }}
          />
        </Sheet>
      )}
    </div>
  );
};

export default Plain;
