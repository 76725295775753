import React, { useEffect, useState } from "react";
import profil from "../img/profil2.webp";
import anime from "animejs";

interface WhoAmIProps {
  ref1: React.RefObject<any>;
  ref2: React.RefObject<any>;
  ref3: React.RefObject<any>;
  inView1: boolean;
  inView2: boolean;
  inView3: boolean;
}

const WhoAmI: React.FC<WhoAmIProps> = ({
  ref1,
  ref2,
  ref3,
  inView1,
  inView2,
  inView3,
}) => {
  type TextAlign =
    | "left"
    | "right"
    | "center"
    | "justify"
    | "initial"
    | "inherit";
  type FlexWrap = "wrap";

  const styles = {
    title: {
      marginTop: window.innerWidth < 690 ? 0 : window.innerWidth < 1300 ? 0 : 0,
      marginBottom: window.innerWidth < 690 ? 20 : 80,
      textAlign: "center" as TextAlign,
      fontFamily: "MADE_Gentle",

      fontSize:
        window.innerWidth < 500
          ? "45px"
          : window.innerWidth < 500
          ? "50px"
          : window.innerWidth < 600
          ? "60px"
          : window.innerWidth < 1000
          ? "70px"
          : window.innerWidth < 1340
          ? "70px"
          : "90px",
      color: "#2d6a4d",
      width: "100vw",
      opacity: 0,
    },
    text: {
      fontSize:
        window.innerWidth < 1220
          ? "19px"
          : window.innerWidth < 1360
          ? "20px"
          : window.innerWidth < 1700
          ? "21px"
          : "22px",
      color: "black",
      textAlign: "center" as TextAlign,
      fontFamily: "Inter",
      fontWeight: "light",
      // paddingLeft: window.innerWidth < 500 ? 5 : 30,
      // paddingRight: window.innerWidth < 500 ? 5 : 30,
      width: window.innerWidth < 800 ? " 95%" : "70%",
      margin: "0 auto",
    },
  };

  const [animTitle, setAnimTitle] = useState(false);
  useEffect(() => {
    if (inView1) {
      setAnimTitle(true);
    }
  }, [inView1]);

  useEffect(() => {
    if (animTitle) {
      anime({
        targets: ref1.current,
        translateY: ["60px", "20px"],
        opacity: [0, 1],
        // scale: [0.8, 1],
        duration: 1000,
        easing: "easeInOutQuad",
      });
    }
  }, [animTitle]);

  return (
    <div>
      <h1 style={styles.title} ref={ref1}>
        Qui suis-je ?
      </h1>
      <img
        src={profil}
        alt="Profile"
        style={{
          width: window.innerWidth < 800 ? 200 : 250,
          height: window.innerWidth < 800 ? 200 : 250,
          objectFit: "cover",
          borderRadius: "50%",
          display: "block",
          margin: window.innerWidth < 800 ? "40px auto" : "60px auto",
        }}
      />
      <div style={styles.text}>
        {window.innerWidth > 800 && (
          <p>
            Infirmière de formation, j’ai mis mon métier entre parenthèses le
            temps d’accompagner mes enfants et de les voir s’envoler vers leur
            vie. Pendant 20 ans, j’ai accompagné d’autres enfants et leurs
            parents en tant qu’assistante maternelle et c’est tout naturellement
            qu’aujourd’hui je renoue avec ce désir d’accompagner l’autre.
            <br />
            <br />
          </p>
        )}

        <p>
          Formée par l’Institut de formation à la sophrologie (IFS ALIOTTA,
          Paris 10, première école de France à avoir obtenu la certification au
          Registre national des compétences professionnelles) et certifiée RNCP,
          je vous propose un suivi personnalisé qui vous permettra d’atteindre
          votre objectif.
          <br />
          <br />
        </p>
        <p>
          Je suis membre adhérente de la Chambre Syndicale de la Sophrologie, et
          je me suis engagée à respecter le code de déontologie que vous pouvez
          retrouver{" "}
          <a
            href="https://www.chambre-syndicale-sophrologie.fr/ethique-deontologie-sophrologue/"
            target="_blank"
            rel="noreferrer"
          >
            ici
          </a>
          {/* En tant que sophrologue, je m'engage à respecter les règles morales et
          éthiques propres à ma profession. Je suis membre de la Chambre
          syndicale de la sophrologie. */}
          <br />
          <br />
        </p>
        {window.innerWidth > 800 && (
          <p>
            Mes accompagnements se font dans la bienveillance, le respect, le
            non jugement et en toute confidentialité.
            {/* <br />
            <br />
            Vous souhaitez prendre rendez-vous ? Contactez moi : 06 31 95 05 66 */}
            {/* Le code de déontologie de la Chambre syndicale de la sophrologie
            définit ces règles essentielles, telles que le respect de la
            confidentialité et de la législation en vigueur, l'obligation
            d’entretenir des relations confraternelles avec les autres
            sophrologues, l’impossibilité de réaliser des prescriptions ou
            diagnostics. */}
            <br />
            <br />
          </p>
        )}
      </div>
    </div>
  );
};

export default WhoAmI;
