import React, { useEffect, useRef, useState } from "react";
import More from "./more";
import { Sheet } from "react-modal-sheet";
import anime from "animejs";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import sheetImage from "../img/background.webp";
import { ReactComponent as Close } from "../svg/close.svg"; // Import du fichier SVG

interface TextLeftProps {
  ref1: React.RefObject<any>;
  ref2: React.RefObject<any>;
  ref3: React.RefObject<any>;
  inView1: boolean;
  inView2: boolean;
  inView3: boolean;
  title: string;
  textPath: string;
  morePath?: string;
  img: string;
}

const TextLeft = ({
  ref1,
  ref2,
  ref3,
  inView1,
  inView2,
  inView3,
  title,
  textPath,
  img,
  morePath,
}: TextLeftProps) => {
  type TextAlign =
    | "left"
    | "right"
    | "center"
    | "justify"
    | "initial"
    | "inherit";
  type FlexWrap = "wrap";
  const styles = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      // padding: "20px",
      flexWrap: "wrap" as FlexWrap,
      // height: window.innerWidth < 1100 ? "auto" : "60vh",
    },
    column: {
      // flex: 1,
      flex: "1 1 480px",
      // margin: window.innerWidth < 500 ? "15px" : "10px",
      width: "100%",
      minWidth: "200px",
      // height: window.innerWidth < 1100 ? "auto" : "60vh",
    },
    title: {
      marginTop:
        window.innerWidth < 690 ? 20 : window.innerWidth < 1300 ? 100 : 120,
      marginBottom: window.innerWidth < 690 ? 40 : 80,
      textAlign: "center" as TextAlign,
      fontFamily: "MADE_Gentle",

      fontSize:
        window.innerWidth < 500
          ? "45px"
          : window.innerWidth < 500
          ? "50px"
          : window.innerWidth < 600
          ? "60px"
          : window.innerWidth < 1000
          ? "70px"
          : window.innerWidth < 1340
          ? "70px"
          : "90px",
      color: "#2d6a4d",
      width: "calc(100% - 40px)",
      paddingLeft: 20,
      paddingRight: 20,
      opacity: 0,
    },
    text: {
      fontSize:
        window.innerWidth < 1220
          ? "19px"
          : window.innerWidth < 1360
          ? "20px"
          : window.innerWidth < 1700
          ? "22px"
          : "24px",
      color: "black",
      textAlign: window.innerWidth < 1080 ? "left" : ("center" as TextAlign),
      fontFamily: "Inter",
      fontWeight: "light",
      paddingLeft: window.innerWidth < 800 ? 5 : 40,
      paddingRight: window.innerWidth < 800 ? 5 : 40,
      // height: "55vh",
    },
    image: {
      width: "100%",
      height: "auto",
      borderRadius: 30,
    },

    textContainer: {
      // textAlign: "left" as TextAlign,
      alignItems: "center",
      display: "flex",
      // flexDirection: "row" as FlexDirection,
      fontSize:
        window.innerWidth < 1220
          ? "18px"
          : window.innerWidth < 1220
          ? "19px"
          : window.innerWidth < 1360
          ? "20px"
          : window.innerWidth < 1700
          ? "22px"
          : "24px",
      color: "black",
      // textAlign: "center" as TextAlign,
      fontFamily: "Inter",
      fontWeight: "light",
      paddingLeft: window.innerWidth < 800 ? 5 : 40,
      paddingRight: window.innerWidth < 800 ? 5 : 40,
    },
    moreContainer: {
      // textAlign: "left" as TextAlign,

      // flexDirection: "row" as FlexDirection,
      fontSize:
        window.innerWidth < 1220
          ? "16px"
          : window.innerWidth < 1220
          ? "17px"
          : window.innerWidth < 1360
          ? "20px"
          : window.innerWidth < 1700
          ? "21px"
          : "22px",
      color: "black",
      // textAlign: "center" as TextAlign,
      fontFamily: "Inter",
      fontWeight: "light",
    },
    sheetContainer: {
      width: window.innerWidth < 800 ? "95vw" : "80vw",
      margin: "auto",
      left: "50%",
      translateX: "-50%",
      borderRadius: "30px 30px 0 0",
      overflow: "hidden",
    },
    imageContainer: {
      // backgroundColor: "red",
      borderRadius: 30,
      maxHeight: "60vh",
      padding: 10,
      // marginRight: window.innerWidth < 500 ? 5 : 70,
      // marginLeft: window.innerWidth < 500 ? 5 : 70,
    },
  };
  const [isOpenSheet, setOpenSheet] = useState(false);

  const [animTitle, setAnimTitle] = useState(false);
  useEffect(() => {
    if (inView1) {
      setAnimTitle(true);
    }
  }, [inView1]);

  const titleRef = useRef(null);

  useEffect(() => {
    if (animTitle) {
      anime({
        targets: ref1.current,
        translateY: ["60px", "20px"],
        opacity: [0, 1],
        // scale: [0.8, 1],
        duration: 1000,
        easing: "easeInOutQuad",
      });
    }
  }, [animTitle]);

  // const filePath = "/md/test.md";
  const [markdown, setMarkdown] = useState("");
  const [markdownMore, setMarkdownMore] = useState("");

  useEffect(() => {
    const fetchMarkdown = async () => {
      const response = await fetch(textPath);
      const text = await response.text();
      setMarkdown(text);
      if (morePath) {
        const responseMore = await fetch(morePath);
        const textMore = await responseMore.text();
        setMarkdownMore(textMore);
      }
    };

    fetchMarkdown();
  }, []);

  const closeModal = () => setOpenSheet(false);

  return (
    <div ref={titleRef}>
      <h1 style={styles.title} ref={ref1} className="title">
        {title}
      </h1>
      <div>
        <div style={styles.container}>
          <div
            style={{ ...styles.column, ...styles.textContainer }}
            className="markdown"
          >
            {/* <p style={styles.text}>
              {text.map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
            </p> */}
            <div
              style={{
                maxWidth: "800px",
                maxHeight: "100%",
                position: "relative",
                left: "50%",
                translate: "-50%",
              }}
            >
              <Markdown remarkPlugins={[remarkGfm]}>{markdown}</Markdown>
            </div>
          </div>
          {/* {window.innerWidth > 800 && ( */}
          <div style={{ ...styles.column, ...styles.imageContainer }}>
            <img
              src={img}
              alt="placeholder"
              style={{
                objectFit: "cover",
                maxWidth: "100%",
                maxHeight: "55vh",
                position: "relative",
                left: "50%",
                translate: "-50%",
                borderRadius: 30,
                // boxShadow: "5px 5px 9px 0px rgba(17,31,24,0.48)",
              }}
            />
          </div>
          {/* )} */}
        </div>
        {morePath && (
          <div
            onClick={() => {
              setOpenSheet(true);
            }}
            style={{
              position: "sticky",
              bottom: 10,
              width: "fit-content",
              // height: "55px",
              display: "flex",
              justifyContent: "center",
              // marginBottom: 10,
              borderRadius: 100,
              // padding: 10,
              userSelect: "none",
              zIndex: 150,
              margin: "15px auto",
            }}
          >
            <More ref1={ref3} inView1={inView3} />
          </div>
        )}
      </div>

      {morePath && (
        <Sheet
          isOpen={isOpenSheet}
          onClose={() => closeModal()}
          disableDrag={/^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          )}
        >
          <Sheet.Container style={styles.sheetContainer}>
            <Sheet.Header />
            <button
              className="close-button"
              onClick={closeModal}
              style={{
                width: "50px",
                height: "50px",
                zIndex: 100,
                borderRadius: "50%",
                position: "absolute",
                top: "20px",
                right: "20px",
                border: "none",
                background: "rgba(183, 244, 195, 0.65)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Close width="24" height="24" fill="#2a533f" />
            </button>
            <Sheet.Content style={styles.moreContainer}>
              <Sheet.Scroller draggableAt="both">
                <img
                  src={sheetImage}
                  alt="fond"
                  style={{
                    userSelect: "none",
                    // borderRadius: "30px 30px 0 0",
                    width: "calc(100% - 90px)",
                    borderRadius: "20px",
                    height: window.innerWidth > 600 ? "35%" : "20%",
                    objectFit: "cover",
                    objectPosition: "50% 20%",
                    margin: "auto",
                    // position: "absolute",
                    top: 0,
                    transform: "translateX(-50%)",
                    left: "50%",
                    position: "relative",
                  }}
                />
                <div style={{ padding: 30 }} className="markdownMore">
                  <Markdown remarkPlugins={[remarkGfm]}>
                    {markdownMore}
                  </Markdown>
                </div>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop
            style={{
              backgroundColor: " rgba(255, 255, 255, 0.2)",
              backdropFilter: "blur(10px)",
            }}
          />
        </Sheet>
      )}
    </div>
  );
};

export default TextLeft;
