import React, { useEffect, useRef, useState } from "react";
import anime from "animejs";
import AudioWhite from "../lottie/lottie_audio_white.json";
import Audio from "../lottie/lottie_audio.json";
import { ReactComponent as Play } from "../svg/play.svg"; // Import du fichier SVG
// import audioAdult from "../audio/SophroAdulte.mp3";
// import audioAdult from "../audio/SophroAdulte.mp3";

import Lottie from "lottie-react";

interface SophrologieProps {
  ref1: React.RefObject<any>;
  ref2: React.RefObject<any>;

  inView1: boolean;
  inView2: boolean;
}
type FlexWrap = "wrap";

const Sound = ({ ref1, ref2, inView1, inView2 }: SophrologieProps) => {
  type TextAlign =
    | "left"
    | "right"
    | "center"
    | "justify"
    | "initial"
    | "inherit";
  // const asterix = require("../audio/audio.flac");
  const styles = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      // padding: "20px",
      flexWrap: "wrap" as FlexWrap,
      height: window.innerWidth < 1000 ? "auto" : "55vh",
    },
    column: {
      // flex: 1,
      flex: "1 1 400px",
      borderRadius: window.innerWidth < 800 ? "0" : "0 30px  30px 0",
      width: "100%",
      minWidth: "200px",
      height: window.innerWidth < 500 ? "auto" : "55vh",
    },
    title: {
      marginTop: 100,
      marginBottom: window.innerWidth < 690 ? 40 : 80,
      textAlign: "center" as TextAlign,
      fontFamily: "MADE_Gentle",

      fontSize:
        window.innerWidth < 500
          ? "45px"
          : window.innerWidth < 500
          ? "50px"
          : window.innerWidth < 600
          ? "60px"
          : window.innerWidth < 1000
          ? "70px"
          : window.innerWidth < 1340
          ? "70px"
          : "90px",
      color: "#2d6a4d",
      width: "calc(100% - 40px)",
      paddingLeft: 20,
      paddingRight: 20,
      opacity: 0,
    },
    adulte: {
      textAlign: "center" as TextAlign,
      fontFamily: "MADE_Gentle",
      paddingTop: "50px",
      fontSize:
        window.innerWidth < 500
          ? "40px"
          : window.innerWidth < 500
          ? "45px"
          : window.innerWidth < 600
          ? "50px"
          : window.innerWidth < 1000
          ? "60px"
          : window.innerWidth < 1340
          ? "60px"
          : "80px",
      color: "white",
      width: "100%",
    },
    enfant: {
      textAlign: "center" as TextAlign,
      fontFamily: "MADE_Gentle",
      paddingTop: "70px",

      fontSize:
        window.innerWidth < 500
          ? "40px"
          : window.innerWidth < 500
          ? "45px"
          : window.innerWidth < 600
          ? "50px"
          : window.innerWidth < 1000
          ? "60px"
          : window.innerWidth < 1340
          ? "60px"
          : "80px",
      color: "#2d6a4d",
      width: "100%",
    },
    text: {
      fontSize: "22px",
      color: "black",
      textAlign: "left" as TextAlign,
      fontFamily: "Inter",
      fontWeight: "light",
      padding: "30px",
    },
    image: {
      width: "100%",
      height: "auto",
    },
    textContainer: {
      textAlign: "left" as TextAlign,
      alignItems: "center",
      // display: "flex",
    },
    sheetContainer: {
      width: "80vw",
      margin: "auto",
      left: "50%",
      translateX: "-50%",
      borderRadius: "30px 30px 0 0",
    },
    left: {
      background: "#2d6a4d",
    },
    right: {
      background: "white",
    },
  };

  const [animTitle, setAnimTitle] = useState(false);
  useEffect(() => {
    if (inView1) {
      setAnimTitle(true);
    }
  }, [inView1]);

  const titleRef = useRef(null);

  useEffect(() => {
    if (animTitle) {
      anime({
        targets: ref1.current,
        translateY: ["50px", "0px"],
        opacity: [0, 1],
        // scale: [0.8, 1],
        duration: 1000,
        easing: "easeInOutQuad",
      });
    }
  }, [animTitle]);
  const audioRefWhite = useRef(null);
  const audioRef = useRef(null);
  const audioPlayerAdult = useRef<HTMLAudioElement | null>(null);
  const audioPlayerChild = useRef<HTMLAudioElement | null>(null);

  const [playAdult, setPlayAdult] = useState(false);
  const [playChild, setPlayChild] = useState(false);
  const audioAdult = require("../audio/SophroAdulte.mp3");
  const audioChild = require("../audio/SophroEnfant.mp3");

  // const [play, { stop }] = useSound(audioAdult, {
  //   volume: 1.3,
  // });

  useEffect(() => {
    if (playAdult) {
      if (audioPlayerAdult.current) {
        audioPlayerAdult.current.play();
      }
    } else {
      if (audioPlayerAdult.current) {
        audioPlayerAdult.current.pause();
      }
    }
  }, [playAdult]);

  useEffect(() => {
    if (playChild) {
      if (audioPlayerChild.current) {
        audioPlayerChild.current.play();
      }
    } else {
      if (audioPlayerChild.current) {
        audioPlayerChild.current.pause();
      }
    }
  }, [playChild]);

  return (
    <div ref={titleRef}>
      <h1 style={styles.title} ref={ref1} className="title">
        Écouter une séance
      </h1>

      <div style={styles.container}>
        <div style={{ ...styles.column, ...styles.left }}>
          <div style={styles.textContainer}>
            <h2 style={styles.adulte} className="adulte">
              Adulte
            </h2>
            <h3
              style={{
                textAlign: "center" as TextAlign,
                fontFamily: "Inter",

                fontSize:
                  window.innerWidth < 500
                    ? "20px"
                    : window.innerWidth < 500
                    ? "20px"
                    : window.innerWidth < 600
                    ? "22px"
                    : window.innerWidth < 1000
                    ? "22px"
                    : window.innerWidth < 1340
                    ? "25px"
                    : "30px",
                color: "white",
                width: "100%",
                position: "relative",
                // top: "0px",
                marginTop: "20px",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              Audio : sophronisation de base
            </h3>

            <audio
              src={audioAdult}
              controls={false}
              ref={audioPlayerAdult}
            ></audio>
          </div>
          <div
            style={{
              position: "relative",
              // left: "50%",
              bottom: "0px",
              margin: "auto",
              // transform: "translateX(-50%)",
              // background: "red",
            }}
            onClick={() => {
              setPlayAdult(!playAdult);
              if (playChild) {
                setPlayChild(false);
              }
            }}
          >
            {!playAdult ? (
              <div
                style={{
                  height: "300px",
                  position: "relative",
                  left: "50%",
                  // top: "-50%",
                  transform: "translateX(-50%) translateY(0%)",
                  width: "400px",
                  maxWidth: "100vw",
                }}
              >
                <Play
                  fill="white"
                  style={{
                    opacity: 1,
                    position: "relative",
                    left: "50%",
                    // top: "-50%",
                    transform: "translateX(-50%) translateY(0%)",
                    top: "35%",
                    width: "400px",
                    maxWidth: "100vw",
                  }}
                />
              </div>
            ) : (
              <Lottie
                animationData={AudioWhite}
                width={50}
                color="white"
                lottieRef={audioRefWhite}
                // ref={mouseRef}
                style={{
                  opacity: 1,
                  position: "relative",
                  left: "50%",
                  top: "-50%",
                  transform: "translateX(-50%) translateY(0%)",
                  maxHeight: 300,
                  width: "400px",
                }}
              />
            )}
          </div>
        </div>
        <div style={{ ...styles.column, ...styles.right }}>
          <h2 style={styles.enfant} className="enfant">
            Enfant
          </h2>
          <h3
            style={{
              textAlign: "center" as TextAlign,
              fontFamily: "Inter",

              fontSize:
                window.innerWidth < 500
                  ? "20px"
                  : window.innerWidth < 500
                  ? "20px"
                  : window.innerWidth < 600
                  ? "22px"
                  : window.innerWidth < 1000
                  ? "22px"
                  : window.innerWidth < 1340
                  ? "25px"
                  : "30px",
              color: "#2d6a4d",
              width: "100%",
              position: "relative",
              // top: "0px",
              marginTop: "20px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            Audio : ma couleur détente
          </h3>
          <audio
            src={audioChild}
            controls={false}
            ref={audioPlayerChild}
          ></audio>
          <div
            style={{
              position: "relative",
              // left: "50%",
              bottom: "0px",
              margin: "auto",
              // transform: "translateX(-50%)",
              // background: "red",
              overflow: "hidden",
            }}
            onClick={() => {
              setPlayChild(!playChild);
              if (playAdult) {
                setPlayAdult(false);
              }
            }}
          >
            {!playChild ? (
              <div
                style={{
                  height: "300px",
                  position: "relative",
                  left: "50%",
                  // top: "-50%",
                  transform: "translateX(-50%) translateY(0%)",

                  width: "400px",
                  maxWidth: "100vw",
                }}
              >
                <Play
                  fill="#2d6a4d"
                  style={{
                    opacity: 1,
                    position: "relative",
                    left: "50%",
                    // top: "-50%",
                    transform: "translateX(-50%) translateY(0%)",
                    top: "35%",
                    width: "400px",
                    maxWidth: "100vw",
                  }}
                />
              </div>
            ) : (
              <Lottie
                animationData={Audio}
                width={50}
                color="white"
                lottieRef={audioRef}
                // ref={mouseRef}
                style={{
                  opacity: 1,
                  position: "relative",
                  left: "50%",
                  top: "-50%",
                  transform: "translateX(-50%) translateY(0%)",
                  maxHeight: 300,
                  width: "400px",
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sound;
