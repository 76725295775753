import React, { useEffect, useLayoutEffect, useState } from "react";
import TextLeft from "../assets/components/textLeft";
import TextRight from "../assets/components/textRight";
import { useInView } from "@react-spring/web";
import Navbar from "../assets/components/navbar";
import Bottom from "../assets/components/bottom";

const Sophrologie: React.FC = () => {
  const [ref1, inView1] = useInView({});
  const [ref2, inView2] = useInView({});
  const [ref3, inView3] = useInView({});
  const [ref4, inView4] = useInView({});
  const [ref5, inView5] = useInView({});
  const [ref6, inView6] = useInView({});
  const [ref7, inView7] = useInView({});
  const [ref8, inView8] = useInView({});
  const [ref9, inView9] = useInView({});

  const styles = {
    page2: {
      minHeight: "85vh",
      width: "100vw",
      backgroundColor: "white",
    },
  };
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    // Nettoyage de l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isOnTop, setIsOnTop] = useState(false);
  useLayoutEffect(() => {
    if (!isOnTop) {
      window.scrollTo(0, 0);
      setIsOnTop(true);
    }
  });
  return (
    <div>
      <Navbar />
      <div style={{ height: 60 }}></div>
      <div style={styles.page2}>
        <TextLeft
          ref1={ref1}
          ref2={ref2}
          ref3={ref3}
          inView1={inView1}
          inView2={inView2}
          inView3={inView3}
          title={"La sophrologie"}
          textPath={"/md/sophrologie.md"}
          img={"/sophrologie_bulle.webp"}
          morePath="/md/more/sophrologie_more.md"
        />
      </div>

      <div style={styles.page2}>
        <TextRight
          ref1={ref4}
          ref2={ref5}
          ref3={ref6}
          inView1={inView4}
          inView2={inView5}
          inView3={inView6}
          title={"Pour qui ?"}
          img={"/pourqui.webp"}
          textPath={"/md/pourqui.md"}
          morePath="/md/more/pourqui_more.md"
        />
      </div>

      <div style={styles.page2}>
        <TextLeft
          ref1={ref7}
          ref2={ref8}
          ref3={ref9}
          inView1={inView7}
          inView2={inView8}
          inView3={inView9}
          title={"Pour quoi ?"}
          textPath={"/md/pourquoi.md"}
          img={"/sophrologie_pourquoi.webp"}
          morePath="/md/more/pourquoi_more.md"
        />
      </div>
      <Bottom />
    </div>
  );
};

export default Sophrologie;
